import {getUpdatedAnime, getNewMovies, getUpdatedSeries} from "../../../../api/api";
import Content from "./Content/Content";
import '../Home.scss'
import NewContent from "../NewContents/NewContent/NewContent";

import {useEffect, useState} from "react";

import Slider from "react-slick";
import {FaArrowRight, FaArrowLeft} from "react-icons/fa";

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiperStyle.css"


const Contents = ({type}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const settings = {
        // dots: true,
        // autoplay: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: false,
                    // dots: true
                }
            },
            {
                breakpoint: 1425,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    // dots: true
                }
            },
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    // dots: true
                }
            },
            {
                breakpoint: 910,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    infinite: false,
                    swipeToSlide: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                    swipeToSlide: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 510,
                settings: {
                    slidesToShow: 2,
                    infinite: false,
                    swipeToSlide: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    infinite: false,
                    swipeToSlide: true,
                    slidesToScroll: 1
                }
            }
        ]
    }

    useEffect(() => {
        if (type === 'movie') {
            getNewMovies().then(result => {
                setData(result);
                setIsLoading(false);
            })
        } else if (type === 'series') {
            getUpdatedSeries().then(result => {
                setData(result);
                setIsLoading(false);
            })
        } else {
            getUpdatedAnime().then(result => {
                setData(result);
                setIsLoading(false);
            })
        }

    }, []);

    if (isLoading) {
        return (
            <Swiper
                slidesPerView={6}
                // spaceBetween={100}
                slidesPerGroup={6}
                loop={true}
                loopFillGroupWithBlank={false}
                navigation={true}
                modules={[Navigation]}
                autoHeight={true}
                // className="swiper-button-next"
            >
                {[1, 2, 3, 4, 5, 6].map(e => (
                    <SwiperSlide key={e}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Content
                                style={{pointerevents: "none"}}
                                data={
                                    {
                                        id: 0,
                                        type: type,
                                        posters: null,
                                        title: 'Loading ...',
                                        year: null,
                                        rating: null
                                    }
                                }
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }

    return (
        <Slider {...settings} className="contents-slider">
            {data.map((content) => (
                <Content
                    data={content}
                    key={content._id}
                />
            ))}
        </Slider>
    )
}

export default Contents
