import "./Search.scss"
import {searchByTitle} from "../../../../api/api"
import LinkToDownloadPage from "../../LinkToDownloadPage/LinkToDownloadPage"
import React, {useEffect, useState} from "react";
import {Link, useResolvedPath} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import colors from "../../../../colors.scss";


const Search = ({handleSearchValue}) => {
    const [searchValue, setSearchValue] = useState([]);
    const [debouncedValue, setDebouncedValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const delay = 500;

    const searchOnKeyUp = (event) => {
        setSearchValue(event.target.value);
        setData([])
    }

    const path = useResolvedPath()
    useEffect(() => {
        setSearchValue('');
    }, [path.pathname])

    useEffect(
        () => {
            if (searchValue === '') {
                document.getElementById('searchInput').value = '';
                setDebouncedValue(searchValue);
                setIsLoading(false);
            } else {
                const handler = setTimeout(() => {
                    setDebouncedValue(searchValue);
                }, delay);
                return () => {
                    clearTimeout(handler);
                };
            }
        }, [searchValue]);

    useEffect(
        () => {
            if (debouncedValue.length <= 3) {
                setDebouncedValue(searchValue);
                setData([]);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                searchByTitle(debouncedValue)
                    .then(result => {
                        if (result === 404) {
                            setData('No matches :(')
                        } else {
                            setData(result)
                        }
                        setIsLoading(false)
                    })
            }
        }, [debouncedValue]);

    return (
        <div style={{marginRight: "50px"}}>
            <label>
                <input id="searchInput" className="search-input" type="text" placeholder="Search here ..."
                       onKeyUp={(e) => searchOnKeyUp(e)}/>
            </label>
            {data.length === 0 && !isLoading
                ?
                <></>
                :
                <div className="search-div">
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <ClipLoader color={colors.secondaryColor} loading={isLoading} size={50}
                                    aria-label="Loading Spinner"/>
                    </div>
                    {data.length > 0 && data !== 'No matches :('
                        ?
                        data.map(item => (
                            <LinkToDownloadPage
                                usage={'search bar'}
                                data={item}
                                key={item._id}
                            />
                        ))
                        :
                        <div>{data}</div>
                    }
                </div>
            }
        </div>
    )
}

export default Search


