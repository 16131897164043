import {Link} from "react-router-dom";
import {useEffect} from "react";

const Profile = ({handleSearchValue}) => {

    useEffect(() => {
        handleSearchValue('');
    }, []);

    return (
        <div>
            Profile
        </div>
    )
}

export default Profile
