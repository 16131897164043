import {NavLink as Link} from "react-router-dom";
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai";
import "./Navbar.scss"
import Search from "./Search/Search";
import {useState} from "react";

const Navbar = ({handleSearchValue}) => {
    const [mobileMenu, setMobileMenu] = useState(false)
    return (
        <header className="navbar">
            <div className="mobile-menu-button" onClick={() => setMobileMenu(!mobileMenu)}>
                {mobileMenu ? <AiOutlineClose size={35}></AiOutlineClose> : <AiOutlineMenu size={35}></AiOutlineMenu>}
            </div>
            <nav>
                <ul className={mobileMenu ? "menu active" : "menu"}>
                    <li>
                        <Link onClick={() => setMobileMenu(!mobileMenu)} className="nav-link" to={"/"}>Home</Link>
                    </li>
                    <li>
                        <Link onClick={() => setMobileMenu(!mobileMenu)} className="nav-link" to={"/profile"}>Profile</Link>
                    </li>
                    <li>
                        <Link onClick={() => setMobileMenu(!mobileMenu)} className="nav-link" to={"/about"}>About</Link>
                    </li>
                </ul>
            </nav>
            <Search handleSearchValue={handleSearchValue}/>
        </header>

        // <div className="navbar">
        //     <div style={{display: "flex", marginLeft: "70px"}}>
        //         <Link to={"/"} style={{marginLeft: "0"}} className="navbar-link">Home</Link>
        //         <Link to={"/profile"} className="navbar-link">Profile</Link>
        //         <Link to={"/about"} className="navbar-link">About</Link>
        //     </div>
        //     <div style={{display: "flex", marginRight: "70px"}}>
        //         <Search handleSearchValue={handleSearchValue}/>
        //         <Link to={"/register"} className="navbar-link">Register</Link>
        //         <Link to={"/login"} style={{marginRight: "0"}} className="navbar-link">Login</Link>
        //     </div>
        // </div>
    )
}

export default Navbar


