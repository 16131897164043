import {Link} from "react-router-dom";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./LinkToDownloadPage.scss"
import colors from "../../../colors.scss";


const LinkToDownloadPage = ({usage, data}) => {

    return (
        <Link className="link-to-download-page"
              to={`/${data.type}/${data._id}/${data.title.split(' ').join('-') + '-' + data.year}`}
              state={data}
        >
            {usage === 'home content'
                ?
                <div className="image">
                    {data.posters !== null
                        ?
                        <img src={data.posters[0].url} alt={""}/>
                        :
                        <ClipLoader color={colors.secondaryColor} loading={true} size={50}
                                    aria-label="Loading Spinner"/>
                    }
                </div>
                :
                usage === 'new content'
                    ?
                    <div className="new-content-poster">
                        {data.posters !== null
                            ?
                            <img src={data.posters[0].url} alt={""}/>
                            :
                            <ClipLoader color={colors.secondaryColor} loading={true} size={50}
                                        aria-label="Loading Spinner"/>
                        }
                    </div>
                    :
                    usage === 'search bar'
                        ?
                        <div style={{display: "flex"}}>
                            <div style={{width: "100px", height: "150px"}}>
                                <img style={{width: "100%", height: "100%"}} src={data.posters[0].url} alt=""/>
                            </div>
                            <p>{data.title}</p>
                        </div>
                        :
                        usage === 'search'
                            ?
                            <div>search</div>
                            :
                            <></>
            }
        </Link>
    )
}

export default LinkToDownloadPage
