import React from "react";
import LinkToDownloadPage from "../../../LinkToDownloadPage/LinkToDownloadPage"


const Content = ({data}) => {

    return (
        <div className="content">
            <LinkToDownloadPage
                usage={'home content'}
                data={data}
            />
            <div style={{display: "flex", justifyContent: "center", width: '100%'}}>
                <div className="description">
                    <div> {data.title} </div>
                    <div> {data.year} </div>
                </div>
            </div>
        </div>
    )
}

export default Content
