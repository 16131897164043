import attack from "../../../../attack.jpg";
import NewContent from "./NewContent/NewContent";
import "./NewContents.scss";
import '../Home.scss'
import {useEffect, useState} from "react";
import Slider from "react-slick";
import {FaArrowRight, FaArrowLeft} from "react-icons/fa";
import {} from "react-icons";

import {getNew} from "../../../../api/api";

const NewContents = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dataIndex, setDataIndex] = useState(0);

    const NextArrow = ({onClick}) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight/>
            </div>
        )
    }

    const PrevArrow = ({onClick}) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft/>
            </div>
        )
    }

    const settings = {
        infinite: true, //to allow the slides to show infinitely
        lazyLoad: true,
        speed: 500, //this is the speed of slider in ms
        slidesToShow: 5, //number of slides to show up on screen
        centerMode: true,
        centerPadding: 0,
        // nextArrow: <NextArrow/>,
        // prevArrow: <PrevArrow/>,
        beforeChange: (current, next) => setDataIndex(next),
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    // dots: true
                }
            },
        ]
    };


    useEffect(() => {
        getNew().then(result => {
            setData(result);
            setIsLoading(false);
        })
    }, [])

    return (
        <Slider {...settings} className="new-contents">
            {data.map((newContent, idx) => (
                <div className={idx === dataIndex ? "slide activeSlide" : "slide"} key={idx}>
                    <NewContent
                        className="slide-content"
                        data={newContent}
                    />
                </div>
            ))}
        </Slider>
    )
}

export default NewContents


