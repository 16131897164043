import MovieLink from "./MovieLink";
import "./MovieLinks.scss"

const MovieLinks = ({qualities}) => {
    return (
        <div className="links">
            {qualities.map((q, index) => (
                <div key={index}>
                    <h2>{q.quality}</h2>
                    {q.links.map((l, index) => (
                        <MovieLink
                            link={l}
                            src={q.sourceName}
                            key={index}
                        />
                    ))}
                </div>
            ))}
        </div>
    )
}

export default MovieLinks