import {useEffect} from "react";

const Login = ({handleSearchValue}) => {

    useEffect(() => {
        handleSearchValue('');
    }, []);

    return (
        <div>
            Login
        </div>
    )
}

export default Login


