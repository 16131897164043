import SeasonLinks from "./SeasonLinks";
import "./SeriesLinks.scss"

const SeriesLinks = ({selectedQuality, seasonsData}) => {
    return (
        <div>
            {seasonsData.map(s => (
                <div style={{marginTop: "40px"}} key={s.seasonNumber}>
                    <h2>Season {s.seasonNumber}</h2>
                    <SeasonLinks
                        selectedQuality={selectedQuality}
                        seasonData={s}
                        key={s.seasonNumber}
                    />
                </div>
            ))}
        </div>
    )
}

export default SeriesLinks
