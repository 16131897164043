import './Home.scss'
import '../App.scss'
import NewContents from "./NewContents/NewContents";
import Contents from "./Contents/Contents";
import {CustomImage} from "../../Atoms"
import {useEffect} from "react";
import {getNewMovies, getUpdatedAnime, getUpdatedSeries} from "../../../api/api";

const Home = ({handleSearchValue}) => {
    useEffect(() => {
        handleSearchValue('');
    }, []);

    return (
        <div>
            <div style={{marginBottom: "120px"}}>
                <h2>New Contents</h2>
                <div>
                    <NewContents/>
                </div>
            </div>

            <div style={{marginBottom: "100px"}}>
                <h2>Movies</h2>
                <div>
                    <Contents type={'movie'}/>
                </div>
            </div>

            <div style={{marginBottom: "100px"}}>
                <h2>Series</h2>
                <div>
                    <Contents type={'series'}/>
                </div>
            </div>

            <div style={{marginBottom: "100px"}}>
                <h2>Anime</h2>
                <div>
                    <Contents type={'anime'}/>
                </div>
            </div>
        </div>
    )
}

export default Home


