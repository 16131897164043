import React from "react";
import LinkToDownloadPage from "../../../LinkToDownloadPage/LinkToDownloadPage"


const NewContent = ({data}) => {
    return (
        <div className="new-content">
            <LinkToDownloadPage
                usage={'new content'}
                data={data}
            />
            {/*<div style={{display: "flex", justifyContent: "center", width: '100%'}}>*/}
            {/*    <div className="description">*/}
            {/*        <div> {data.title} </div>*/}
            {/*        <div> {data.year} </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default NewContent
