import {useEffect} from "react";

const Register = ({handleSearchValue}) => {

    useEffect(() => {
        handleSearchValue('');
    }, []);

    return (
        <div>
            SignUp
        </div>
    )
}

export default Register


