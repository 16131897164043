import "./SeriesLinks.scss"
import EpisodeLink from "./EpisodeLink";

const episodesFilteredWithInfo = (info, episodes) => {
    let filteredEpisodes = []
    episodes.map(e => e.links.map(l => {
        if (l.info === info)
            filteredEpisodes.push(
                {
                    episodeNumber: e.episodeNumber,
                    title: e.title,
                    url: l.link,
                    source: l.sourceName
                }
            )
    }))
    return filteredEpisodes
}

const arraySlices = (filteredEpisodes) => {
    let len = filteredEpisodes.length
    let numberOfRowContent = 10
    let slices = []
    if (len > numberOfRowContent) {
        let start = 0
        let end = 0
        while (end < len) {
            start = end
            end += numberOfRowContent
            slices.push({start: start, end: end})
        }
    } else {
        slices = [{start: 0, end: len}]
    }
    return slices
}

const InfoAccordion = ({info, seasonData}) => {
    let filteredEpisodes = episodesFilteredWithInfo(info, seasonData.episodes)
    let slices = arraySlices(filteredEpisodes)

    return (
        <div className="accordion">
            <details>
                <summary>
                    {info}
                </summary>
                <div>
                    <div>
                        {slices.map((range) => (
                            <div className="accordion-contents"
                                 key={info + seasonData.seasonNumber.toString() + range.start}>
                                {filteredEpisodes.slice(range.start, range.end).map(e => (
                                    <EpisodeLink
                                        episode={e}
                                        key={info + e.source + seasonData.seasonNumber.toString() + e.episodeNumber.toString()}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </details>
        </div>
    )
}

// return (
//     <div className="accordion">
//         <details>
//             <summary>
//                 {info}
//             </summary>
//             <div style={{display: "flex", justifyContent: "center"}}>
//
//                 <div className="accordion-contents">
//                     {filteredEpisodes.map(e => (
//                         <EpisodeLink
//                             episode={e}
//                             key={seasonData.seasonNumber.toString() + e.episodeNumber.toString()}
//                         />
//                     ))}
//                 </div>
//
//             </div>
//         </details>
//     </div>
// )

export default InfoAccordion
