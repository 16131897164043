import React, {useEffect, useState} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {searchById} from "../../../api/api";
import "./DownloadPage.scss";
import IMDB_logo from "./IMDB_logo.jpg";
import rotten_logo from "./rotten_logo.png";
import metacritic_logo from "./metacritic_logo.png";
import MAL_logo from "./MAL_logo.png";
import {useLocation, useParams} from "react-router-dom";
import SeriesLinks from "./SeriesLinks/SeriesLinks";
import MovieLinks from "./MovieLinks/MovieLinks";
import colors from "../../../colors.scss";


const DownloadPage = ({handleSearchValue}) => {
    const params = useParams();
    const location = useLocation();

    const [data, setData] = useState(() => {
        const lowData = location.state

        if (lowData === null)
            return null

        lowData.summary = {
            english: 'Loading ...'
        }
        lowData.genres = ['Loading ...']
        lowData.seasons = []
        return lowData
    });
    const [isLoading, setIsLoading] = useState(true);
    const [qualityFilter, setQualityFilter] = useState({});
    const [selectedSeason, setSelectedSeason] = useState('All');
    const [seriesInfo, setSeriesInfo] = useState([]);

    useEffect(() => {
        handleSearchValue('');
        setIsLoading(true);
        searchById(params.id).then(result => {
            let allQualities = ['360p', '480p', '720p', '1080p', '2160p']
            let qFilter = {'All': true}
            allQualities.map(q => qFilter[q] = false)
            setQualityFilter(qFilter)

            // Getting all available info for series
            if (!result.type.includes('movie')) {
                let allAvailableInfo = []
                result.seasons.map(s => s.episodes.map(e =>
                    e.links.map(l => {
                        if (!allAvailableInfo.includes(l.info))
                            allAvailableInfo.push(l.info)
                    })
                ))
                setSeriesInfo(allAvailableInfo)
            }

            setData(result)
            setIsLoading(false)
        })
    }, [params.id]);

    if (data === null) {
        return (
            <div style={{marginTop: "100px"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <ClipLoader color={colors.secondaryColor} loading={true} size={100} aria-label="Loading Spinner"/>
                </div>
            </div>
        )
    }

    const handleSelectedQuality = (e) => {
        let qFilter = {...qualityFilter}
        Object.keys(qFilter).map(key => qFilter[key] = key === e.target.value)
        setQualityFilter(qFilter)
    }

    const handleSelectedSeason = (e) => {
        let selectedSeasonNumber = e.target.value
        setSelectedSeason(selectedSeasonNumber)
    }

    return (
        <div className="main-div">
            <div className="info-div">
                <div className="poster">
                    <img src={data.posters[0].url} alt={""}/>
                </div>
                {/*Description*/}
                <div className="description-div">
                    <h1 style={{textAlign: "center"}}>{data.rawTitle} {data.year}</h1>
                    <label className="description-title">Summury: </label>
                    <p style={{lineHeight: 1.5, textAlign: "justify", marginTop: "5px"}}>{data.summary.english}</p>
                    <div style={{display: "flex"}}>
                        <label className="description-title">Genre(s): </label>
                        {data.genres.map((genre, index) => (
                            <label style={{textTransform: "capitalize", marginLeft: "5px"}} key={index}>
                                {index === data.genres.length - 1 ? genre : `${genre},`}
                            </label>
                        ))}
                    </div>
                    <div style={{display: "flex", marginTop: "10px", marginBottom: "10px"}}>
                        <div style={{marginRight: "10px"}}>
                            <label className="description-title">Year: </label>
                            <label>{data.year}</label>
                        </div>
                        <div style={{marginRight: "10px"}}>
                            <label className="description-title">Language(s): </label>
                            <label style={{textTransform: "capitalize"}}>{data.movieLang}</label>
                        </div>
                        <div>
                            <label className="description-title">Country(ies): </label>
                            <label style={{textTransform: "capitalize"}}>{data.country}</label>
                        </div>
                    </div>
                    {/*Ratings*/}
                    <div style={{display: "flex", marginTop: "20px"}}>
                        <div style={{marginRight: "20px", display: "flex", alignItems: "center"}}>
                            <img className="logo" src={IMDB_logo} alt={""}/>
                            <span>{data.rating.imdb}</span>
                        </div>
                        {data.type.includes('anime')
                            ?
                            <div style={{display: "flex"}}>
                                <img style={{display: "flex", alignItems: "center"}} className="logo" src={MAL_logo}
                                     alt={""}/>
                                <span>{data.rating.myAnimeList}</span>
                            </div>
                            :
                            <div style={{display: "flex"}}>
                                <div style={{marginRight: "20px", display: "flex", alignItems: "center"}}>
                                    <img className="logo" src={rotten_logo} alt={""}/>
                                    <span>{data.rating.rottenTomatoes}</span>
                                </div>
                                <div style={{marginRight: "20px", display: "flex", alignItems: "center"}}>
                                    <img className="logo" src={metacritic_logo} alt={""}/>
                                    <span>{data.rating.metacritic}</span>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            {/*Download links*/}
            <div style={{marginTop: "50px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    {/*Download links qualities filter*/}
                    <div className="qualities">
                        {Object.keys(qualityFilter).map((q, index) => (
                            <div key={q + index}>
                                <input id={`${index}`}
                                       type="radio"
                                       value={q}
                                       name="quality"
                                       key={index}
                                       onChange={(e) => handleSelectedQuality(e)}
                                       checked={qualityFilter[q]}
                                />
                                <label
                                    htmlFor={`${index}`}
                                    key={q}>{q}
                                </label>
                            </div>
                        ))}
                    </div>
                    {/*Download links seasons filter (only for series)*/}
                    {!data.type.includes('movie')
                        ?
                        <select className="seasonSelect" onChange={(e) => handleSelectedSeason(e)}>
                            {[{seasonNumber: 'All'}].concat(data.seasons)
                                .map((s, index) => (
                                    <option value={s.seasonNumber} key={index}>Season: {s.seasonNumber}</option>
                                ))}
                        </select>
                        : null
                    }
                </div>
                {/*Movie or series links*/}
                <div className="links">
                    {isLoading
                        ?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ClipLoader color={colors.secondaryColor} loading={true} size={100}
                                        aria-label="Loading Spinner"/>
                        </div>
                        :
                        data.type.includes('movie')
                            ?
                            <MovieLinks
                                qualities={qualityFilter.All
                                    ?
                                    data.qualities
                                    :
                                    data.qualities
                                        .filter(q => q.quality === Object.keys(qualityFilter)
                                            .filter(key => qualityFilter[key])[0])}
                            />
                            :
                            <SeriesLinks
                                selectedQuality={Object.keys(qualityFilter).filter(key => qualityFilter[key])[0]}
                                seasonsData={selectedSeason === 'All'
                                    ?
                                    data.seasons
                                    :
                                    data.seasons.filter(s => s.seasonNumber === parseInt(selectedSeason))}
                            />
                    }
                </div>
            </div>
        </div>
    )
}

export default DownloadPage
