import "./SeriesLinks.scss"

const EpisodeLink = ({episode}) => {
    return (
        <div>
            <a className="episode-link" href={episode.url}>
                Episode {episode.episodeNumber}
                {/*| {episode.title.length < 10*/}
                {/*?*/}
                {/*episode.title*/}
                {/*:*/}
                {/*episode.title.slice(0, 11) + ' ...'}*/}
            </a>
        </div>
    )
}

export default EpisodeLink
