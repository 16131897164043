import axios from "axios";


const API = axios.create({
    baseURL: window?._env_?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

export const getNewMovies = async () => {
    return API
        .get(`/movies/news/movie/low/0-10/0-10/1?testUser=true`)
        .then(response => response.data.data)
        .catch(e => {
            console.log(e);

            return "Error"
        });
}

export const getUpdatedSeries = async () => {
    return API
        .get(`/movies/updates/serial/low/0-10/0-10/1?testUser=true`)
        .then(response => response.data.data)
        .catch(error => null);
}

export const getUpdatedAnime = async () => {
    return API
        .get(`/movies/news/anime_serial/low/0-10/0-10/1?testUser=true`)
        .then(response => response.data.data)
        .catch(error => null);
}

export const searchById = async (id) => {
    return API
        .get( `/movies/searchbyid/${id}/high?testUser=true`)
        .then(response => response.data.data)
        .catch(error => null);
}

export const searchByTitle = async (searchValue) => {
    console.log(searchValue)
    return API
        .get(`/movies/searchMovie/high/1`, {
            params: {
                title: searchValue,
                testUser: true
            }
        })
        .then(response => response.data.data)
        .catch(error => 404);
}

export const getNew = async () => {
    return API
        .get(`/movies/sortedMovies/animeTopAiring/serial-anime_serial/low/0-10/0-10/1`, {
            params: {
                testUser: true
            }
        })
        .then(response => response.data.data)
        .catch(error => 404);
}



