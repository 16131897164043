import "./MovieLinks.scss"

const createLinkDescription = (linkInfo, sourceName) => {
    let info = linkInfo.split(' - ')
    return info[0].replace('.', ' ') + ' | ' + sourceName + ' | ' + info[1]
}

const MovieLink = ({link, src}) => {
    return (
        <div>
            <div className="link">
                <p>{createLinkDescription(link.info, src)}</p>
                <a href={link.link}>
                    <button>Download</button>
                </a>
            </div>
        </div>
    )
}

export default MovieLink