import './App.scss';
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Register from "./Register/Register";
import Login from "./Login/Login";
import About from "./About/About";
import Navbar from "./Navbar/Navbar";
import NotFound from "./NotFound/NotFound";
import {Route, Routes, useLocation, useResolvedPath} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DownloadPage from "./DownloadPage/DownloadPage";
import Profile from "./Profile/Profile";


const handleSearchValue = () => {

}

function App() {
    return (
        <div className="App">
            <Navbar handleSearchValue={handleSearchValue}/>
            <div className="App-contents">
                <Routes>
                    <Route path={"/"} element={<Home handleSearchValue={handleSearchValue}/>}/>
                    <Route path={"/about"} element={<About handleSearchValue={handleSearchValue}/>}/>
                    <Route path={"/profile"} element={<Profile handleSearchValue={handleSearchValue}/>}/>
                    <Route path={"/register"} element={<Register handleSearchValue={handleSearchValue}/>}/>
                    <Route path={"/login"} element={<Login handleSearchValue={handleSearchValue}/>}/>
                    <Route path={"/:type/:id/:titleAndYear"}
                           element={<DownloadPage handleSearchValue={handleSearchValue}/>}/>
                    <Route path="*" element={<NotFound handleSearchValue={handleSearchValue}/>}></Route>
                </Routes>
            </div>
            {/*<Footer/>*/}
        </div>
    );
}

export default App;
