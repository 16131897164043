import "./SeriesLinks.scss"
import InfoAccordion from "./InfoAccordion";

const uniqueInfo = (selectedQuality, seasonData) => {
    let info = []
    // All unique link info for this season
    seasonData.episodes.map(e =>
        e.links.map(l => {
            if (!info.includes(l.info))
                info.push(l.info)
        })
    )
    // Filter with quality
    info = selectedQuality === 'All' ? info : info.filter(i => i.includes(selectedQuality))
    return info
}

const SeasonLinks = ({selectedQuality, seasonData}) => {
    let info = uniqueInfo(selectedQuality, seasonData)
    return (
        <div>
            <span className="target-fix" id="accordion"></span>
            {info.map((i, index) => (
                <InfoAccordion
                    key={i + seasonData.seasonNumber}
                    info={i}
                    seasonData={seasonData}
                />
            ))}
        </div>
    )
}

export default SeasonLinks
